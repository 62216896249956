import React, { useState, useEffect, useRef } from "react";
import "./Tile.css";
import { useStore } from "../store/home";
import CampaignIcon from "@mui/icons-material/Campaign";
import part from "../Images/part1.png";
import { useNonPersistedStore } from "../store/NonPersistedStore";
import place from "../Images/placeHolder.png";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as GreyShopList } from "../Images/greyShopList.svg";
import { ReactComponent as Checkmark } from "../Images/checkMark.svg";
import { ReactComponent as Exclamation } from "../Images/notCompatible.svg";
import classnames from "classnames";
import { ReactComponent as RedCart } from "../Images/redCart.svg";
import NumericInput from "react-numeric-input";
import { toast } from "react-toastify";
import loaderImg from "../Images/loaderImg.gif";
import { Box, Modal } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { addToCart, getMyCart, updateWishList } from "../api/posts";
import CircularProgress from "@mui/material/CircularProgress";
import {
  addCommaDecimal,
  addCommaDecimalreturnZ,
  getCurrentDate,
} from "../commonFunction";
import { AddEmployeeAccountBtnStyled } from "./StyledComponents/EmployeeAccountStyled";
import Skeleton from "react-loading-skeleton";
import AddToCartPopUp from "./AddToCartPopUp";
import AddToShopPop from "./AddToShopPopUp";

const Tile = ({ item }) => {
  let details = navigator.userAgent;
  let regexp = /ipad/i;
  let isMobileDevice = regexp.test(details);

  const navigate = useNavigate();
  const {
    dealerId,
    userInfo,
    token,
    isCurrentEngSet,
    setMyCartMini,
    currentDevice,
    myProfile,
  } = useStore();
  const [quantity, setQuantity] = useState(1);
  const handleProductClick = (id) => {
    navigate({
      pathname: "/part",
      search: `number=${id}`,
    });
  };
  const location = useLocation();
  const { refreshQ } = useNonPersistedStore();

  const [shopListPop, setShopListPop] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenShop, setIsModalOpenShop] = useState(false);

  useEffect(() => {
    setQuantity(1);
  }, [refreshQ]);

  const handleInputChangeSelect = (event) => {
    // if(event<1){
    //   setQuantity(null)
    // } else{
    setQuantity(event);
  };
  const notify = () => toast.success("Product added to cart");
  const notifyShop = () => toast.success("Product added to Shopping list");

  const mutation = useMutation((post) => updateWishList(post, token), {
    onSuccess: (result) => {
      let arr = result?.data?.data
      if(result?.data?.data && result?.data?.data?.length > 0 && myProfile?.profileInfo?.role === "ServiceTechnician"){
         arr = result?.data?.data.filter(item => item.isPermission)
      }
      setShopListPop(arr);
      setIsModalOpenShop(true);
      // notifyShop();
    },
    onError: (error) => {},
  });

  const email = userInfo.username;
  const mutation_cart = useMutation((post) => getMyCart(post, token), {
    onSuccess: (result) => {
      setMyCartMini(result);
    },
    onError: (error) => {},
  });

  // useEffect(() => {
  //   const obj = {
  //     email: userInfo.username,
  //     dealerId: dealerId,
  //     minicart: true,
  //   };
  //   mutation_cart.mutate(obj);
  // }, []);

  const mutationCart = useMutation((post) => addToCart(post, token), {
    onSuccess: (result) => {
      // notify();
      const obj = {
        email: userInfo.username,
        minicart: true,
        dealerId: dealerId,
      };
      setShopListPop(result?.data?.cart?.items);
      setIsModalOpen(true);
      mutation_cart.mutate(obj);
    },
    onError: (error) => {},
  });

  const handleAddToShoppingList = () => {
    const arr1 = [];
    if (item?.additionalInfoFlag) {
      item.additionalInfo.forEach((item) => {
        const obj = {};
        obj.title = item;
        obj.value = "";
        arr1.push(obj);
      });
    }
    const arr = {
      partId: item.partId,
      quantity: quantity <= 1 ? 1 : quantity,
      isPartDetail: false,
      dealerId:dealerId,
      date: getCurrentDate(),
      notes: "",
      other_notes: arr1,
    };
    mutation.mutate(arr);
  };

  const handlePart = (data) => {
    navigate({
      pathname: "/part",
      search: `number=${data}`,
    });
  };

  const handleAddToCart = () => {
    const arr1 = [];
    if (item?.additionalInfoFlag) {
      item.additionalInfo.forEach((item) => {
        const obj = {};
        obj.title = item;
        obj.value = "";
        arr1.push(obj);
      });
    }
    const arr = {
      partId: item.partId,
      quantity: quantity <= 1 ? 1 : quantity,
      // date: getCurrentDate(),
      notes: "",
      otherNotes: arr1,
    };
    let obj = [];
    obj.push(arr);
    let data = {
      email: userInfo?.username,
      update: false,
      isPartDetail: false,
      items: obj,
    };
    mutationCart.mutate(data);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const [isSelecting, setIsSelecting] = useState(false);
  const divRef = useRef(null);

  const handleMouseDown = () => {
    setIsSelecting(false);
  };

  const handleMouseMove = () => {
    setIsSelecting(true);
  };

  const handleMouseUp = (e ,flag) => {
    if (!isSelecting) {
      flag && handleProductClick(e);
    }
  };

  const handleError = () => {
    setHasError(true);
    setIsLoading(false);
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  return (
    <div className={`cardParentDesktop`}>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="add-to-cart-modal"
        aria-describedby="add-to-cart-modal-description"
      >
        <div className="addToCart-modal">
          <AddToCartPopUp
            shopList={shopListPop}
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
          />
        </div>
      </Modal>
      <Modal
        open={isModalOpenShop}
        onClose={() => setIsModalOpenShop(false)}
        aria-labelledby="add-to-cart-modal"
        aria-describedby="add-to-cart-modal-description"
      >
        <div className="addToCart-modal">
          <AddToShopPop
            setIsModalOpen={setIsModalOpenShop}
            shopList={shopListPop}
            yourCart={true}
            isModalOpen={isModalOpenShop}
          />
        </div>
      </Modal>
      <div
        className={classnames("cardDesktop", {
          borderForGreyNo: location.pathname === "/catalog",
        })}
      >
        <div

          ref={divRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={(e)=>{handleMouseUp(item.partNumber,!item?.isDiscontinue?.status)}}
          // onClick={() => {
          //   !item?.isDiscontinue?.status && handleProductClick(item.partNumber);
          // }}
          className={`card__innerdivDesktop`}
        >
          {!item.isLoad ? (
            <span
              className={`card__newTextDesktop ${
                item.productLabel ? "" : " dpHidden"
              }`}
            >{`${item.productLabel}`}</span>
          ) : (
            <>
              {" "}
              <Skeleton width={40} height={10} />
            </>
          )}
          {isMobileDevice ? (
            <div id="child" className={`card__imageDivTablet`}>
              {(isLoading || item.isLoad) && (
                <div className="loaderDiv center">
                  <Skeleton
                    circle
                    height="150px"
                    style={{ margin: "0px 10px 0px 0px" }}
                    width="150px"
                    containerClassName="avatar-skeleton"
                  />
                </div>
              )}
              {!item.isLoad ? (
                <img
                  className={`card__imageDesktop`}
                  onLoad={handleLoad}
                  onError={handleError}
                  style={{ display: isLoading || hasError ? "none" : "block" }}
                  src={item?.images ? item?.images : place}
                  alt={
                    item?.imagesAltText ||
                    item?.partNumber ||
                    "Ilmor Part Image"
                  }
                />
              ) : (
                <></>
                // <Skeleton
                //   circle
                //   height="150px"
                //   style={{ margin: "0px 10px 0px 0px" }}
                //   width="150px"
                //   containerClassName="avatar-skeleton"
                // />
              )}
            </div>
          ) : (
            <div id="child" className={`center card__imageDiv${currentDevice}`}>
              {(isLoading || item.isLoad) && (
                <div className="loaderDiv center">
                  <Skeleton
                    circle
                    height="150px"
                    style={{ margin: "0px 10px 0px 0px" }}
                    width="150px"
                    containerClassName="avatar-skeleton"
                  />
                </div>
              )}
              {!item.isLoad ? (
                <img
                  className={`card__imageDesktop`}
                  onLoad={handleLoad}
                  onError={handleError}
                  style={{
                    display:
                      isLoading || hasError || item.isLoad ? "none" : "block",
                  }}
                  src={item?.images ? item?.images : place}
                  alt={
                    item?.imagesAltText ||
                    item?.partNumber ||
                    "Ilmor Part Image"
                  }
                />
              ) : (
                <></>
                // <Skeleton
                //   circle
                //   height="150px"
                //   style={{ margin: "0px 10px 0px 0px" }}
                //   width="150px"
                //   containerClassName="avatar-skeleton"
                // />
              )}
            </div>
          )}
          <div className="cardMinHeightDiv">
            <div style={{ display: "flex" }}>
              {isLoading ? (
                <></>
              ) : (
                <>
                  {!item.isLoad ? (
                    <span className="headingText mt">
                      {`${item.partNumber}`}{" "}
                    </span>
                  ) : (
                    <>
                      {" "}
                      <Skeleton width={40} height={10} />
                    </>
                  )}
                </>
              )}
              {/* <span className="headigSubText mt">(Replaces 93914-12)</span> */}
            </div>
            {!item.isLoad ? (
              <span className="subheadingText mt">{`${
                item?.partName && item?.partName?.length < 50
                  ? item?.partName
                  : item?.partName?.slice(0, 50) + "..."
              }`}</span>
            ) : (
              <>
                {" "}
                <Skeleton width={40} height={10} />
              </>
            )}
            {!item.isLoad ? (
              <span className="backordered mt">
                {" "}
                {item?.productBackOrder &&
                !item.isBasePart &&
                item?.inventory <= item?.inStockQuantity
                  ? "Backordered"
                  : ""}
              </span>
            ) : (
              <>
                <Skeleton width={40} height={10} />
              </>
            )}
            <div
              className="mt5"
              style={{ display: "flex", alignItems: "center" }}
            >
              {item?.isBasePart ? (
                <span style={{ fontWeight: 700, marginRight: "8px" }}>
                  {!item.isLoad ? (
                    `${
                      myProfile?.profileInfo?.permissions?.includes(
                        "add_to_own_cart"
                      )
                        ? "From:"
                        : ""
                    }`
                  ) : (
                    <Skeleton width={40} height={10} />
                  )}
                </span>
              ) : (
                <></>
              )}{" "}
              {(myProfile?.profileInfo?.permissions?.includes(
                "add_to_own_cart"
              ) ||
                dealerId) && (
                <>
                  {!item.isLoad ? (
                    <span className="price">{`$${
                      item?.productEffectiveCost &&
                      parseFloat(item?.productEffectiveCost)?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    }`}</span>
                  ) : (
                    <>
                      {" "}
                      <Skeleton width={40} height={10} />
                    </>
                  )}
                  {item?.productOrigionalCost !== item?.productEffectiveCost &&
                    addCommaDecimal(item?.productOrigionalCost) && (
                      <>
                        {!item.isLoad ? (
                          <span className="discountedPrice">{`$${
                            item.productOrigionalCost &&
                            addCommaDecimal(item?.productOrigionalCost)
                          }`}</span>
                        ) : (
                          <>
                            {" "}
                            <Skeleton width={40} height={10} />
                          </>
                        )}
                      </>
                    )}
                </>
              )}
            </div>

            {isCurrentEngSet?.engineId && location?.pathname === "/search" && (
              <>
                {!item.isLoad ? (
                  <Box
                    // className="toppos"
                    sx={{
                      color: item?.isEngineCompartible ? "#40A900" : "#CE0E2D;",
                      // position: "absolute",
                      // top: "60px",
                      right: "0",
                      margin: "2px 0px",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "8px",
                      fontWeight: "700",
                    }}
                  >
                    {item?.isEngineCompartible ? (
                      <>
                        {" "}
                        <Checkmark className={`fillGreen`}></Checkmark>
                        <>Compatible with current engine</>
                      </>
                    ) : (
                      <>
                        <Exclamation className="fillRedNonCompatible"></Exclamation>
                        <>Not compatible with current engine</>
                      </>
                    )}
                  </Box>
                ) : (
                  <></>
                )}
              </>
            )}
            {item?.isSupercedeDiscontinue?.status ? (
              <Box
                sx={{
                  fontWeight: "700",
                  fontSize: "12px",
                  display: "flex",
                }}
              >
                <div className="divSkuTile">
                  {`${"This part is a replacement for "} `}
                  {item?.isSupercedeDiscontinue?.replacementPartNumber &&
                    item?.isSupercedeDiscontinue?.replacementPartNumber.length >
                      0 &&
                    item?.isSupercedeDiscontinue?.replacementPartNumber.map(
                      (itemName, i) => (
                        <>
                          {i < 4 ? (
                            <>
                              <span
                                style={{ color: "#CE0E2D" }}
                                key={itemName}
                              >{`${itemName}`}</span>
                              {i !==
                                item?.isSupercedeDiscontinue
                                  ?.replacementPartNumber.length -
                                  1 && <span>{`, `}</span>}
                            </>
                          ) : (
                            <>
                              <spa>...</spa>
                            </>
                          )}
                        </>
                      )
                    )}
                </div>
              </Box>
            ) : (
              ""
            )}
          </div>
        </div>

        {
          <>
            {isMobileDevice ? (
              <div className={`bottomBoxTablet`}>
                <div className="inputBoxNUmber inputWidth">
                  {!item.isLoad ? (
                    <NumericInput
                      // className={`quickOrderTableQuantityDesktop dfgm2343 fontforTile`}
                      className={classnames("quickOrderTableQuantityDesktop ", {
                        kfghjktf3343: location.pathname === "/search",
                      })}
                      mobile={false}
                      strict
                      max={999}
                      value={quantity <= 0 ? "" : quantity}
                      // min={1}
                      onChange={handleInputChangeSelect}
                      onBlur={(event) => {
                        if (
                          event.target.value == "" ||
                          event.target.value <= 0
                        ) {
                          handleInputChangeSelect(1);
                        }
                      }}
                      // value={quantity}
                    />
                  ) : (
                    <>
                      {" "}
                      <Skeleton width={200} height={50} />
                    </>
                  )}
                </div>
                {!item.isLoad ? (
                  <div className="iconWrapper center bgForCart">
                    {mutation.isLoading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      <GreyShopList
                        onClick={handleAddToShoppingList}
                        className="GreyShopList icon"
                      ></GreyShopList>
                    )}
                  </div>
                ) : (
                  <>
                    {" "}
                    <Skeleton width={200} height={50} />
                  </>
                )}
                {myProfile?.profileInfo?.permissions?.includes(
                  "add_to_own_cart"
                ) && (
                  <div className="iconWrapper center bgForCartRed">
                    {mutationCart.isLoading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      <RedCart
                        onClick={handleAddToCart}
                        className="GreyShopList icon"
                      ></RedCart>
                    )}
                  </div>
                )}
              </div>
            ) : item?.isDiscontinue?.status ? (
              <>
                {" "}
                <Box
                  sx={{
                    backgroundColor: "#F7F7F7",
                    width: "100%",
                    padding: "10px 10px 10px 28px",
                    opacity: currentDevice == "Desktop" ? "0" : "1",
                    // "&:hover": {
                    //   opacity: currentDevice == "Desktop" ? "0" : "1",
                    // },
                  }}
                  className="opacityCarddDis"
                >
                  <Box
                    sx={{
                      color: "#CE0E2D",
                      fontWeight: "700",
                      fontSize: "12px",
                    }}
                  >
                    Discontinued
                  </Box>
                  {item?.isDiscontinue?.replacementPartNumber ? (
                    <Box
                      sx={{
                        // marginTop: "4px",
                        fontWeight: "700",
                        fontSize: "12px",
                      }}
                    >
                      <span>{`Use part `}</span>
                      <span
                        onClick={() => {
                          handlePart(item?.isDiscontinue?.replacementPartId);
                        }}
                        className="under"
                      >
                        {item?.isDiscontinue?.replacementPartNumber}
                      </span>
                      <span>{` instead`}</span>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        marginTop: "4px",
                        fontWeight: "700",
                        fontSize: "12px",
                      }}
                    >
                      Contact your Ilmor rep for support
                    </Box>
                  )}
                </Box>
              </>
            ) : item?.isBasePart ? (
              <>
                {!item.isLoad ? (
                  <AddEmployeeAccountBtnStyled
                    className="opacityCard"
                    sx={{
                      width: "100%",
                      opacity: currentDevice == "Desktop" ? "0" : "1",
                    }}
                    onClick={() => {
                      handleProductClick(item?.partNumber);
                    }}
                  >
                    Select Options
                  </AddEmployeeAccountBtnStyled>
                ) : (
                  <>
                    {/* {" "}
                    <Skeleton width={200} height={50} />{" "} */}
                  </>
                )}
              </>
            ) : (
              <div className={`bottomBox${currentDevice}`}>
                <div className="inputBoxNUmber inputWidth">
                  {!item.isLoad ? (
                    <NumericInput
                      className={`quickOrderTableQuantityDesktop dfgm2343 fontforTile`}
                      mobile={false}
                      max={999}
                      value={quantity <= 0 ? "" : quantity}
                      // min={1}
                      strict
                      onChange={handleInputChangeSelect}
                      onBlur={(event) => {
                        if (
                          event.target.value == "" ||
                          event.target.value <= 0
                        ) {
                          handleInputChangeSelect(1);
                        }
                      }}
                      // value={quantity}
                    />
                  ) : (
                    <>
                      {/* {" "}
                      <Skeleton
                        style={{ margin: "0px 0px 0px 30px" }}
                        width={200}
                        height={50}
                      /> */}
                    </>
                  )}
                </div>
                {!item.isLoad ? (
                  <div className="iconWrapper center bgForCart">
                    {mutation.isLoading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      <GreyShopList
                        onClick={handleAddToShoppingList}
                        className="GreyShopList icon"
                      ></GreyShopList>
                    )}
                  </div>
                ) : (
                  <>
                    {/* <Skeleton
                      style={{ margin: "0px 0px 0px 30px" }}
                      width={200}
                      height={50}
                    /> */}
                  </>
                )}
                {myProfile?.profileInfo?.permissions?.includes(
                  "add_to_own_cart"
                ) && (
                  <>
                    {!item.isLoad ? (
                      <div className="iconWrapper center bgForCartRed">
                        {mutationCart.isLoading ? (
                          <CircularProgress
                            style={{ color: "white" }}
                            size={20}
                          />
                        ) : (
                          <RedCart
                            onClick={handleAddToCart}
                            className="GreyShopList icon"
                          ></RedCart>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        }
      </div>
    </div>
  );
};

export default Tile;
