import React, { useState, useEffect, useRef } from "react";
import "./AddTaxExemptForm.css";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useStore } from "../store/home";
import DatePicker from "react-datepicker";
import { ReactComponent as Cross } from "../Images/crossRed.svg";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ReactComponent as CALENDAR } from "../Images/calendar.svg";
import { ReactComponent as CLOSE } from "../Images/closeButton.svg";
import { Box, Button, Grid, Stack } from "@mui/material";
import {
  StyledShippingHeading,
  StyledShippingSubHeading,
  labelDesktopAddShippingRequired,
  StyledRequiredField,
} from "../components/StyledComponents/ShippingAddressStyled";
import Dropzone from "react-dropzone";
import { storeAdditionalTaxDetails } from "../api/posts";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { ContainerDivStyled } from "./StyledComponents/BusinessDetailsStyled";
import TabletViewBlackContainer from "./TabletViewBlackContainer";
import { OrderStatusStyled } from "./StyledComponents/EmployeeAccountStyled";
import { useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../utils/Toaster";
import AccountHome from "./AccountHome/AccountHome";
import Loader from "../commenComp/Loader";
import commonMessages from "../commenComp/CommonMessages";

const AddTaxExemptForm = () => {
  const {
    currentDevice,
    token,
    setstoreTax,
    setSideMenuAccount,
    userInfo,
    myProfile,
    dealerId,
  } = useStore();
  const navigate = useNavigate();

  const onSubmit = (data, event) => {
    event.preventDefault();
    // let startDate = data.signedDate;

    const syear = data.signedDate.getFullYear();
    const smonth = String(data.signedDate.getMonth() + 1).padStart(2, "0");
    const sday = String(data.signedDate.getDate()).padStart(2, "0");
    const formattedsignedDate = `${syear}-${smonth}-${sday}`;

    const eyear = data.expirationDate.getFullYear();
    const emonth = String(data.expirationDate.getMonth() + 1).padStart(2, "0");
    const eday = String(data.expirationDate.getDate()).padStart(2, "0");
    const formattedexpirationDate = `${eyear}-${emonth}-${eday}`;

    let formData = new FormData();
    // formData.append('file',attachmentArray[0])
    formData.append("exemptionReason", data.exemptionReason);
    formData.append("startDate", formattedsignedDate);
    formData.append("expiryDate", formattedexpirationDate);
    formData.append("taxCertificate", attachmentArray[0]);
    formData.append("email", userInfo?.username);
    // formData.append('email','karan.singh@happiestminds.com')
    // const obj = {
    //   exemptionReason: data.exemptionReason,
    //   startDate: data.signedDate,
    //   expiryDate: data.expirationDate,
    //   taxCertificate: formData,
    //   email: 'karan.singh@happiestminds.com',
    // };
    // mutation.mutate(obj);
    mutation.mutate(formData);
    //   ...data,
    //   exemptionReason:data?.exemptionReason?.value,
    //   signedDate:data?.signedDate?.value,
    //   expirationDate:data?.expirationDate?.value,
    //   formData:data?.formData?.value,
    // });
  };

  const schema = yup.object().shape({
    exemptionReason: yup.string().required("Reason is required"),
    signedDate: yup
      .date()
      .required("Date is required")
      .typeError("Invalid Date"),
    expirationDate: yup
      .date()
      .required("Date is required")
      .typeError("Invalid Date"),
    fileUpload: yup.mixed().required("File is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      exemptionReason: "",
      signedDate: "",
      expirationDate: "",
      fileUpload: "",
    },
    resolver: yupResolver(schema),
  });

  const [attachmentArray, setAttachmentArray] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const mutation = useMutation(
    (post) => storeAdditionalTaxDetails(post, token),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            reset();
            setAttachmentArray([]);
            showSuccessToast(result?.data?.msg);
          } else {
            showErrorToast(result?.data?.msg);
          }
        }
        setstoreTax(result);
      },
      onError: (error) => {},
    }
  );
  // const email = localStorage.getItem("email");

  const handleValidateFiles = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      const fileSizeLimit = 10; // Maximum file size limit in MB
      const errorMessage = `File size exceeds the ${fileSizeLimit}MB limit.`;
      showErrorToast(errorMessage);
      // Show the error message to the user in your UI
    } else {
      if (acceptedFiles.length > 1) {
        showErrorToast("More than one file cannot be selected");
      } else {
        setAttachmentArray([...acceptedFiles, ...attachmentArray]);
        const fl = [...acceptedFiles, ...attachmentArray];
        let arrayImage = [];
        for (let i = 0; i < fl?.length; i++) {
          arrayImage.push(fl[i]);
        }
        if (fl?.length > 1) {
          // showErrorToast("You can upload only one file")
        } else {
          setIsFileUploaded(true);
          setAttachmentArray(arrayImage);
          // setUploadFiles(arrayImage);
        }
      }
    }
  };
  const removeFile = (file) => () => {
    const newFiles = [...attachmentArray];
    newFiles.splice(newFiles.indexOf(file), 1);
    setAttachmentArray(newFiles);
  };
  const handlecal = () => {
    const containerElement = document.getElementById("dfd43432");
    containerElement.focus();
  };
  const handlecal2 = () => {
    const containerElement = document.getElementById("drtuu446");
    containerElement.focus();
  };
  const ref = useRef(null);
  const handleKeyDown = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const containerElement = document.getElementById("dfd43432");
    containerElement.setAttribute("onkeydown", "return false");
    containerElement.setAttribute("readonly", "true");
    const containerElement1 = document.getElementById("drtuu446");
    containerElement1.setAttribute("onkeydown", "return false");
    containerElement1.setAttribute("readonly", "true");

    if (
      !(
        !dealerId &&
        myProfile?.profileInfo?.role !== "ServiceTechnician" &&
        myProfile?.profileInfo?.USDealer &&
        myProfile?.profileInfo?.permissions?.includes("manage_tax_exempt_form")
      )
    ) {
      setTimeout(() => {
        showErrorToast("You don't have permission to submit tax form");
      }, 200);
      navigate("/account");
    }
  }, []);

  return (
    <ContainerDivStyled className="taxformpadTabletSet">
      {mutation.isLoading && <Loader></Loader>}
      <Grid container className="main taxformpadTabletSetgridContainer">
        <Grid item xs={12} sm={12} md={12} lg={7}>
          <Grid container spacing={1}>
            <Grid
              item
              sm={12}
              md={12}
              className="taxformpadTabletSetGreyTAblet"
            >
              <StyledShippingHeading currentDevice={currentDevice}>
                <div className="taxformpad">
                  <span className="AddTaxHeading">
                    {commonMessages?.addTaxExemptFormHeading}
                  </span>
                </div>
              </StyledShippingHeading>
            </Grid>
            {currentDevice === "Tablet" && (
              <Grid item sm={12} md={12}>
                <TabletViewBlackContainer />
              </Grid>
            )}

            <Grid className="sdfsdf33634c" item sm={12} md={12}>
              <div className="padfortabtac">
                <StyledShippingSubHeading>
                  {commonMessages?.addTaxExemptFormSubHeading}
                </StyledShippingSubHeading>{" "}
              </div>
            </Grid>
            <Grid item sm={8} md={12}>
              <StyledRequiredField>
                {commonMessages?.addEmployeeAccountRequiredField}
              </StyledRequiredField>
            </Grid>
          </Grid>
          {myProfile?.taxExemptionForm?.status && <>
          <hr className="hrLineBreak" />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <h5 className="exemption styuytu dateHeading labelDesktopAddShipping ">
                Active tax forms
              </h5>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <h5 className="dateHeading styuytu labelDesktopAddShipping dateHeadingTabletsigned">
                Document Name
              </h5>
              <div className='center344'>
              <InsertDriveFileOutlinedIcon/>
              <span onClick={()=>{window.open(myProfile?.taxExemptionForm?.document, '_blank');}} className={`orderHisTableData curp`}>{myProfile?.taxExemptionForm?.filename && myProfile?.taxExemptionForm?.filename.length >10 ? myProfile?.taxExemptionForm?.filename.slice(0,10) + '...': myProfile?.taxExemptionForm?.filename}</span>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <h5 className="dateHeading styuytu labelDesktopAddShipping dateHeadingTabletsigned">
                SIGNED DATE
              </h5>
              <span className={`orderHisTableData`}>{myProfile?.taxExemptionForm?.startDate}</span>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <h5 className="dateHeading styuytu labelDesktopAddShipping dateHeadingTabletsigned">
                EXPIRATION DATE
              </h5>
              <span className={`orderHisTableData`}>{myProfile?.taxExemptionForm?.expiryDate}</span>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <h5 className="dateHeading styuytu labelDesktopAddShipping dateHeadingTabletsigned">
                Status
              </h5>
              {/* <OrderStatusStyled status={myProfile?.taxExemptionForm?.status && 'Active'}>{myProfile?.taxExemptionForm?.status  && 'Active'}</OrderStatusStyled> */}
              <div className='ndfgjhhdfj67'>Active</div>
            </Grid>
          </Grid>
          </>}
          <hr className="hrLineBreak" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <h5 className="exemption styuytu dateHeading labelDesktopAddShippingRequired ">
                  EXEMPTION REASON
                </h5>
                <textarea
                  readOnly={
                    !myProfile?.profileInfo?.permissions?.includes(
                      "manage_tax_exempt_form"
                    ) || !myProfile?.profileInfo?.USDealer
                  }
                  className="Txtarea TxtareaDesktop"
                  type="textarea"
                  name="exReason"
                  minLength={10}
                  maxLength={500}
                  {...register("exemptionReason")}
                />
                {/* <div className='caldate'> */}
                {errors.exemptionReason && (
                  <p className="error">{errors.exemptionReason.message}</p>
                )}
              </Grid>
              <Grid item xs={6} sm={3} md={4}>
                <div className="">
                  <h5 className="dateHeading styuytu labelDesktopAddShippingRequired dateHeadingTabletsigned">
                    SIGNED DATE
                  </h5>
                  <div className="datebg">
                    <CALENDAR onClick={handlecal} className="icon"></CALENDAR>
                    {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}  {...register("signedDate")} />      */}
                    <Controller
                      control={control}
                      name="signedDate"
                      render={({ field }) => (
                        <DatePicker
                          onFocus={(e) => (e.target.readOnly = true)}
                          onChange={(date) => field.onChange(date ? date : "")}
                          selected={field.value}
                          inputMode="none"
                          // readOnly={true}
                          disabledKeyboardNavigation={false}
                          onBeforeInput={(e) => {
                            e.preventDefault();
                          }}
                          id="dfd43432"
                          dateFormat={"MM/dd/yy"}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          maxDate={moment().toDate()}
                          disabled={
                            !myProfile?.profileInfo?.permissions?.includes(
                              "manage_tax_exempt_form"
                            ) || !myProfile?.profileInfo?.USDealer
                          }
                        />
                      )}
                    />
                  </div>
                  {errors.signedDate && (
                    <p className="error">{errors.signedDate.message}</p>
                  )}
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={4}>
                <div>
                  <h5 className="dateHeading styuytu labelDesktopAddShippingRequired dateHeadingTabletsigned">
                    EXPIRATION DATE
                  </h5>
                  <div className="datebg">
                    <CALENDAR onClick={handlecal2} className="icon"></CALENDAR>
                    {/* <DatePicker selected={expiryDate} onChange={(date) => setExpiryDate(date)} {...register("expirationDate")} /> */}
                    <Controller
                      control={control}
                      name="expirationDate"
                      render={({ field }) => (
                        <DatePicker
                          onFocus={(e) => (e.target.readOnly = true)}
                          onChange={(date) => field.onChange(date ? date : "")}
                          selected={field.value}
                          inputMode="none"
                          // readOnly={true}
                          disabledKeyboardNavigation={false}
                          onBeforeInput={(e) => {
                            e.preventDefault();
                          }}
                          id="drtuu446"
                          dateFormat={"MM/dd/yy"}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          minDate={moment().toDate()}
                          disabled={
                            !myProfile?.profileInfo?.permissions?.includes(
                              "manage_tax_exempt_form"
                            ) || !myProfile?.profileInfo?.USDealer
                          }
                        />
                      )}
                    />
                  </div>
                  {errors.expirationDate && (
                    <p className="error">{errors.expirationDate.message}</p>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <h5 className="uploadDoc dateHeading labelDesktopAddShippingRequired dateHeadingTabletsigned">
                  UPLOAD DOCUMENT
                </h5>
                <div className="dropzone">
                  <Dropzone
                    disabled={
                      !myProfile?.profileInfo?.permissions?.includes(
                        "manage_tax_exempt_form"
                      ) || !myProfile?.profileInfo?.USDealer
                    }
                    accept={{
                      // "text/csv": [".csv"],
                      // "image/png": [".png"],
                      // "image/jpg": [".jpg"],
                      "image/jpeg": [".jpeg"],
                      // "image/pdf": [".pdf"],
                      "application/pdf": [".pdf"],
                      // "application/vnd.ms-excel": [".xls"],
                      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                      //   ["..xlsx"],
                    }}
                    // maxFiles={1}
                    maxSize={10485760}
                    style={{
                      width: "40%",
                      height: "20%",
                      border: "10px solid black",
                    }}
                    onDrop={(acceptedFiles, rejectedFiles) =>
                      handleValidateFiles(acceptedFiles, rejectedFiles)
                    }
                    {...register("fileUpload")}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section
                        style={{
                          pointerEvents: `${
                            attachmentArray.length > 0 ? "none" : "unset"
                          }`,
                        }}
                      >
                        <div {...getRootProps()} className="text-center">
                          <input
                            disabled={attachmentArray.length > 0 ? true : false}
                            {...getInputProps()}
                          />
                          <Stack
                            direction="column"
                            className="borderDragNDrop"
                            // alignItems="center"
                          >
                            <Button
                              disabled={
                                attachmentArray.length > 0 ? true : false
                              }
                              className="uploadButton"
                            >
                              <span className="uploadButtonText">
                                Choose File
                              </span>
                            </Button>
                          </Stack>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <div className="uploadAttachment">
                    {errors.fileUpload && (
                      <p className="error">{errors.fileUpload.message}</p>
                    )}
                    {attachmentArray.length > 0
                      ? attachmentArray[0]?.name?.length > 16
                        ? attachmentArray[0]?.name?.slice(0, 16) + "..."
                        : attachmentArray[0]?.name
                      : "No file chosen"}
                    {attachmentArray?.map((file) => (
                      <>
                        <Cross
                          className="icon quickCross"
                          style={{ cursor: "pointer" }}
                          onClick={removeFile(file)}
                        />
                      </>
                    ))}
                  </div>
                </div>
                <div className="nutpadchoosefile">
                  <span>{`(.pdf and .jpg of size less than 10 MB)`}</span>
                </div>
              </Grid>
              <Grid item xs={12} className="tabletBtncancelAddTaxBottom">
                <div className="btn_cancel cancelTaxGreyTabletAddCancel">
                  {/* <button className={`TE_button`}type='button'>Cancel</button> */}
                  <button
                    type="button"
                    className={`TE_button`}
                    onClick={() => {
                      setSideMenuAccount("home");
                      navigate({
                        pathname: "/account",
                      });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className={`ilmorButon addTax`}
                    type="submit"
                    disabled={attachmentArray.length > 0 ? false : true}
                  >
                    Add Tax Exempt Form
                  </button>
                </div>
              </Grid>
            </Grid>
            <Box sx={{ marginBottom: "-60px" }}>
              {currentDevice == "Mobile" && (
                <TabletViewBlackContainer currentDevice={currentDevice} />
              )}
            </Box>
          </form>
        </Grid>
      </Grid>
    </ContainerDivStyled>
  );
};

export default AddTaxExemptForm;
